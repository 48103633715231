<template>
    <div class="content-video-conferencing bg-light px-4 py-4 rounded-5">
        <div id='appointmentDisplay' v-show='!userInLobby && !userInMeeting'>
            <div>
                <button class="secondary red-btn nowrap bottom-20" id="endMeetingButton" v-if="this.$store.state.chime_meeting_id" @click="clear_meeting">
                    <span class="material-icons-outlined">delete_outline</span> Clear Meeting Session
                </button>
                <div v-if="this.$store.state.chime_meeting_id" style="height: calc(80vh);" class="d-flex justify-content-center align-items-center">
                    <b-spinner variant="primary"></b-spinner>
                </div>
                <div id="appointmentDisplay">
                    <div id="userAppointmentDisplay" class='' v-if='!isClientLoggedIn'>
                        <div class="px-4 pt-4" v-if='!!currentAppointment'>
                            <h1 class="fw-bold mb-3">Current Telehealth Meeting</h1>
                            <div id='nextTelehealthMeetingCard' class='d-flex justify-content-between card-block'>
                                <div class="w-75">
                                    <div class="fs-5 mb-3">
                                        <span v-if="currentAppointment.event">{{ currentAppointment.event }}</span>
                                        <span v-else>{{ currentAppointment.appointment }} — {{ currentAppointment.appt_type }}</span>
                                    </div>
                                    <h6><span class='material-icons-outlined me-2'>video_camera_front</span>{{ formatDate(currentAppointment.dayt_appt_start) }}</h6>
                                    <div class="d-flex flex-wrap">
                                        <span class="block-invite-names mt-2" v-for="invitedName in currentAppointment.invitedNames" :key="invitedName.name">
                                            {{ invitedName.name }}
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center" >
                                    <button
                                        class='primary'
                                        @click='startAppointment(currentAppointment)'
                                        v-if='showJoin(currentAppointment.dayt_appt_start, currentAppointment.dayt_appt_end)'     
                                    >
                                        Join Meeting
                                    </button>
                                </div>
                            </div>
                        </div>
    
                        <div class="px-4 pt-2" v-if='!!upcomingAppointments && upcomingAppointments.length > 0'>
                            <h1 class="fw-bold mb-3">Recent and Upcoming Telehealth Meetings</h1>
                            <div id='nextTelehealthMeetingCard' class='card-block'>
                                <div v-for="(appointment, index) in upcomingAppointments" :key="appointment.group_uuid">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-75">
                                            <div class="fs-5 mb-3">
                                                <span v-if="appointment.event">{{ appointment.event }}</span>
                                                <span v-else>{{ appointment.appointment }} — {{ appointment.appt_type }}</span>
                                            </div>
                                            <h6><span class='material-icons-outlined me-2'>video_camera_front</span>{{ formatDate(appointment.dayt_appt_start) }}</h6>
                                            <div class="d-flex flex-wrap">
                                                <span class="block-invite-names mt-2" v-for="invitedName in appointment.invitedNames" :key="invitedName.name">
                                                    {{ invitedName.name }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button
                                                class='primary'
                                                @click='startAppointment(appointment)'
                                                v-if='showJoin(appointment.dayt_appt_start, appointment.dayt_appt_end)'     
                                            >
                                                Join Meeting
                                            </button>
                                        </div>
                                        
                                    </div>
                                    <div class="border-top border-opacity-25 my-4" v-if="index !== (upcomingAppointments.length - 1)"></div>
                                </div>
                            </div>
                        </div>
    
                        <div id="unscheduledMeetingCard" class="px-4 pt-2 meeting-list" v-if="!this.$store.state.chime_meeting_id && (!!currentAppointment || (!!upcomingAppointments && upcomingAppointments.length > 0))"> 
                            <h1 class="fw-bold mb-3">Start an Unscheduled Meeting TEST</h1>
                            <ValidationObserver ref='unscheduledMeetingForm'>
                                <form @submit.prevent>
                                    <div class="row d-flex">
                                        <div class="w-50 px-2">
                                            <Input
                                                inputClass="input-unscheduled-meeting-with-list"
                                                name='Meeting name'
                                                type='text'
                                                id='unscheduledMeetingNameInput'
                                                v-model='unscheduledMeetingName'
                                                placeholder="Enter meeting name here"
                                                required 
                                            />
                                        </div>
                                        <div class="w-25 px-2">
                                            <button class='btn-unscheduled-meeting-with-list fw-bolder ms-2 mt-1' @click='startUnscheduledMeeting' :disabled="isStartButtonDisabled">Join an on-demand meeting</button>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center align-items-center no-meeting-list" v-if="!currentAppointment && upcomingAppointments.length === 0 && !this.$store.state.chime_meeting_id">
            <div class="text-center no-meeting-list-main-block">
                <h1 class="fw-bold mb-5">Start an Unscheduled Meeting TEST</h1>
                <ValidationObserver ref='unscheduledMeetingForm'>
                    <form @submit.prevent>
                        <Input
                            inputClass="input-unscheduled-meeting"
                            name='Meeting name'
                            type='text'
                            id='unscheduledMeetingNameInput'
                            v-model='unscheduledMeetingName'
                            placeholder="Enter meeting name here"
                            required
                        />
                        <button class='btn-unsheduled-meeting fw-bolder mt-1' @click='startUnscheduledMeeting' :disabled="isStartButtonDisabled">Join an on-demand meeting</button>
                    </form>
                </ValidationObserver>
            </div>
        </div>

        <!-- Video Conference Screen -->
        <!-- <div id='meetingDisplay'> -->
        <template v-if='userInMeeting && !userInLobby'>
            <div class="d-flex justify-content-around p-4" style="height: 800px;">
                <div class="w-75" id='vid-parent'>
                    <div class="my-3" id='vid-child'>
                        <div class="position-relative h-85">
                            <div class="potition-relative h-100">
                                <!-- v-bind:class="getClientVideoElementId() != id ? 'd-none' : ''" -->
                                <div v-for='id in videoElementIds' :key='id'>
                                    <template>
                                        <div class="main-video h-100 border" v-if="getClientVideoElementId() == id" >
                                            <!-- <div class='single-video-wrapper'> -->
                                                <video :id='id' @resize='onResize' style="max-height: 100%; height: 600px; border-radius: 15px !important;" class="w-100 bg-secondary"></video>
                                            <!-- </div> -->
                                        </div>
                                        <div class="main-video h-100 border" v-if="!getClientVideoElementId() && !isFullScreen" >
                                            <div style="max-height: 100%; height: 600px; border-radius: 15px !important;" class="w-100 bg-secondary"></div>
                                        </div>
                                        <div class="main-video h-100 border" v-if="!getClientVideoElementId() && isFullScreen" >
                                            <div style="max-height: 100%; height: 800px; border-radius: 15px !important;" class="w-100 bg-secondary"></div>
                                        </div>
                                    </template>
                                </div>

                                <!-- <img src="@/assets/px/demo-person.png" alt="" class="main-video"> -->
                                <div class="other-participants-video-section position-absolute">
                                    <div v-for='id in videoElementIds' :key='id' v-bind:class="getClientVideoElementId() == id ? 'd-none' : ''" class="position-relative position-relative mt-3" style="max-width: 100%;height: 150px;">
                                        <video :id='id' @resize='onResize' class="w-100 bg-info" style="max-height: 150px;height: 150px;max-width: 98%;border-radius: 15px !important;border: 2px solid rgb(84, 80, 80);box-shadow: rgb(107, 216, 191) 0px 0px 0px 0px;"></video>
                                        <template v-if="getClientVideoElementId() != id">
                                            <div class="bg-light position-absolute bottom-0 w-75 other-participants-name">
                                                <h1 class="p-2 bottom-0">{{ attendees[id].name }}</h1>
                                            </div>
                                        </template>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <audio id="NOTmeetingAudioElement"></audio>
                        </div>
                        <div v-if="!isFullScreen" class="d-flex justify-content-evenly p-5">
                            <!-- Mute Unmute Icon Start -->
                            <b-tooltip :target="selfMuteButtonIcon" placement="bottom" :title="selfMuteButtonText"></b-tooltip>
                            <!-- <b-tooltip target="unmute" placement="bottom" title="Unmute"></b-tooltip> -->
                            <div class="d-flex justify-content-center align-items-center essential-icon" :id='selfMuteButtonIcon' @click='toggleSelfMute'>
                                <span class="material-icons"> {{ selfMuteButtonIcon }} </span>
                            </div>
                            <!-- <div class="d-flex justify-content-center align-items-center essential-icon" id='unmute'>
                                <span class="material-icons-outlined"> mic </span>
                            </div> -->
                            <!-- Mute Unmute Icon Stop -->

                            <!-- Video Icon Start -->
                            <b-tooltip :target="videoButtonIcon" placement="bottom" :title="videoButtonText"></b-tooltip>
                            <!-- <b-tooltip target="videoButtonIcon" placement="bottom" :title="videoButtonText"></b-tooltip> -->
                            <div class="d-flex justify-content-center align-items-center essential-icon" :id='videoButtonIcon' v-if='videoInputDeviceExists' @click='toggleVideo'>
                                <span class="material-icons-outlined"> {{ videoButtonIcon }} </span>
                            </div>
                            <!-- <div class="d-flex justify-content-center align-items-center essential-icon" id='start_video' v-if='videoInputDeviceExists' @click='toggleVideo'>
                                <span class="material-icons-outlined"> videocam </span>
                            </div> -->
                            <!-- Video Icon Stop -->

                            <!-- Screen Share Icon Start -->
                            <b-tooltip target="sharing_screen" placement="bottom" title="Sharing Screen"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id='sharing_screen' v-if='userIsHost && browserSupportsScreenShare'
                                :disabled='!contentSharing && contentShareCount > 1'
                                @click='toggleScreenContentShare'>
                                <span class="material-icons-outlined"> present_to_all </span>
                            </div>
                            <!-- Screen Share Icon Stop -->

                            <div class="end-meeting-section w-20" id='endMeetingButton' v-if='userIsHost' @click='endMeeting'>
                                <div class="d-flex justify-content-evenly align-items-center p-2">
                                    <span class="material-icons mx-1"> call_end </span>
                                    <span>End Meeting</span>
                                    <!-- <div class="d-grid">
                                        <span>End Meeting</span>
                                        <span>00:01</span>
                                    </div> -->
                                </div>
                            </div>

                            <!-- Settings Icon Start -->
                            <b-tooltip target="settings" placement="bottom" title="Settings"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id="settings" v-if='browserSupportsDeviceChoosing'
                            @click='openSettingsModal'>
                                <span class="material-icons"> settings </span>
                            </div>
                            <!-- Settings Icon Stop -->

                            <!-- Leave Meeting Icon Start -->
                            <b-tooltip target="leave_meeting" placement="bottom" title="Leave Meeting"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id="leave_meeting" @click='leaveMeeting'>
                                <span class="material-icons-outlined"> logout </span>
                            </div>
                            <!-- Leave Meeting Icon Stop -->

                            <!-- Expand/Collapse Icon Start -->
                            <b-tooltip target="fullscreen" placement="bottom" title="Fullscreen"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" 
                                id='fullscreen' @click="toggleFullscreen">
                                <span class="material-icons-outlined" v-if="!isFullScreen"> open_in_full </span>
                                <span class="material-icons-outlined" v-if="isFullScreen"> close_fullscreen </span>
                            </div>
                            <!-- Expand/Collapse Icon Stop -->

                        </div>
                        <div v-if="isFullScreen" class="d-flex justify-content-evenly p-5 position-absolute " style="width:100%">
                            <!-- Mute Unmute Icon Start -->
                            <b-tooltip :target="selfMuteButtonIcon" placement="bottom" :title="selfMuteButtonText"></b-tooltip>
                            <!-- <b-tooltip target="unmute" placement="bottom" title="Unmute"></b-tooltip> -->
                            <div class="d-flex justify-content-center align-items-center essential-icon" :id='selfMuteButtonIcon' @click='toggleSelfMute'>
                                <span class="material-icons"> {{ selfMuteButtonIcon }} </span>
                            </div>
                            <!-- <div class="d-flex justify-content-center align-items-center essential-icon" id='unmute'>
                                <span class="material-icons-outlined"> mic </span>
                            </div> -->
                            <!-- Mute Unmute Icon Stop -->

                            <!-- Video Icon Start -->
                            <b-tooltip :target="videoButtonIcon" placement="bottom" :title="videoButtonText"></b-tooltip>
                            <!-- <b-tooltip target="videoButtonIcon" placement="bottom" :title="videoButtonText"></b-tooltip> -->
                            <div class="d-flex justify-content-center align-items-center essential-icon" :id='videoButtonIcon' v-if='videoInputDeviceExists' @click='toggleVideo'>
                                <span class="material-icons-outlined"> {{ videoButtonIcon }} </span>
                            </div>
                            <!-- <div class="d-flex justify-content-center align-items-center essential-icon" id='start_video' v-if='videoInputDeviceExists' @click='toggleVideo'>
                                <span class="material-icons-outlined"> videocam </span>
                            </div> -->
                            <!-- Video Icon Stop -->

                            <!-- Screen Share Icon Start -->
                            <b-tooltip target="sharing_screen" placement="bottom" title="Sharing Screen"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id='sharing_screen' v-if='userIsHost && browserSupportsScreenShare'
                                :disabled='!contentSharing && contentShareCount > 1'
                                @click='toggleScreenContentShare'>
                                <span class="material-icons-outlined"> present_to_all </span>
                            </div>
                            <!-- Screen Share Icon Stop -->

                            <div class="end-meeting-section w-20" id='endMeetingButton' v-if='userIsHost' @click='endMeeting'>
                                <div class="d-flex justify-content-evenly align-items-center p-2">
                                    <span class="material-icons mx-1"> call_end </span>
                                    <span>End Meeting</span>
                                    <!-- <div class="d-grid">
                                        <span>End Meeting</span>
                                        <span>00:01</span>
                                    </div> -->
                                </div>
                            </div>

                            <!-- Settings Icon Start -->
                            <b-tooltip target="settings" placement="bottom" title="Settings"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id="settings" v-if='browserSupportsDeviceChoosing'
                            @click='openSettingsModal'>
                                <span class="material-icons"> settings </span>
                            </div>
                            <!-- Settings Icon Stop -->

                            <!-- Leave Meeting Icon Start -->
                            <b-tooltip target="leave_meeting" placement="bottom" title="Leave Meeting"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" id="leave_meeting" @click='leaveMeeting'>
                                <span class="material-icons-outlined"> logout </span>
                            </div>
                            <!-- Leave Meeting Icon Stop -->

                            <!-- Expand/Collapse Icon Start -->
                            <b-tooltip target="fullscreen" placement="bottom" title="Fullscreen"></b-tooltip>
                            <div class="d-flex justify-content-center align-items-center essential-icon" 
                                id='fullscreen' @click="toggleFullscreen">
                                <span class="material-icons-outlined" v-if="!isFullScreen"> open_in_full </span>
                                <span class="material-icons-outlined" v-if="isFullScreen"> close_fullscreen </span>
                            </div>
                            <!-- Expand/Collapse Icon Stop -->

                        </div>
                    </div>
                </div>
                <div class="w-33" v-if='userIsHost'>
                    <div class="my-3 px-5">
                        <div class="h-85">
                            <div class="d-grid">
                                <span class="agenda">Agenda</span>
                                <span class="agenda-title text-dark" v-if="startedAppointment">{{ startedAppointment.appointment }} - {{ startedAppointment.appt_type }}</span>
                                <span class="agenda-title text-dark" v-else>Unscheduled Meeting - {{ unscheduledMeetingName }}</span>
                            </div>
                            <div class="py-3 host-section"> 
                                <div class="d-flex justify-content-start align-items-center">
                                    <!-- <img style="margin-right: 5%; max-width: 40px; max-height: 40px;" src="@/assets/px/Group.png" alt=""> -->
                                    <span class="material-symbols-rounded border member-icon-host"> person </span>
                                    <div class="d-grid justify-content-start">
                                        <span class="username text-dark" v-if="!attendees[videoElementIds[0]]"><b-spinner small variant="secondary"></b-spinner> </span>
                                        <span class="username text-dark" v-else>{{ attendees[videoElementIds[0]] ? attendees[videoElementIds[0]].name : '' }}</span>
                                        <div class="host-tag text-dark" style="min-width: 150px;">
                                            <span>Meeting Host</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2">
                                <span class="agenda-title">Members</span>
                            </div>
                            <div class="d-flex justify-content-between pb-4">
                                <div class="host-tag text-dark px-3">
                                    <span>{{ displayAttendees ? Object.keys(displayAttendees).length : 0 }} out of {{ waitList ? Object.keys(waitList).length : 0 + displayAttendees ? Object.keys(displayAttendees).length : 0 }} people joined</span>
                                </div>
                                <div class="host-tag px-3 accept-all" v-if="waitList && Object.keys(waitList).length > 0" @click="acceptAll()">
                                    <span>Accept all</span>
                                </div>
                                <div class="host-tag px-3 accept-all" v-else>
                                    <span>Accept all</span>
                                </div>
                            </div>
                            <div>
                                <div id="waitlist" style="height: 360px; overflow: auto;">
                                    <template v-if='!unscheduledMeeting'>
                                        <div class="d-flex justify-content-evenly w-100 align-items-center pb-4" v-for='member in waitList' :key='member.encoded_user_id'>
                                            <b-tooltip :target="member.encoded_user_id + 'admit'" placement="bottom" title="Admit"></b-tooltip>
                                            <b-tooltip :target="member.encoded_user_id + 'deny'" placement="bottom" title="Deny"></b-tooltip>
                                            <div class="d-flex justify-content-start w-100 align-items-center">
                                                <!-- <img class="meeting-avatar" src="@/assets/px/Group.png" alt=""> -->
                                                <span class="material-symbols-rounded border member-icon"> person </span>
                                                <span class="ml-3 meeting-name">{{ member.name }}</span>
                                            </div>
                                            <div class="d-flex">
                                                <div class="mx-3 icon-accept d-flex justify-content-center align-items-center" :id="member.encoded_user_id + 'admit'" @click='admitFromWaitList(member.encoded_user_id)'>
                                                    <span class="material-symbols-outlined"> done </span>
                                                </div>
                                                
                                                <div class="mx-3 icon-reject d-flex justify-content-center align-items-center" :id="member.encoded_user_id + 'deny'" @click='removeFromWaitList(member.encoded_user_id)'>
                                                    <span class="material-symbols-outlined"> close </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="d-flex justify-content-evenly w-100 align-items-center pb-4" v-for='attendee in displayAttendees' :key='attendee.id'>
                                        <b-tooltip :target="attendee.id + 'mute'" placement="bottom" title="Unmute"></b-tooltip>
                                        <b-tooltip :target="attendee.id + 'kick'" placement="bottom" title="Remove"></b-tooltip>
                                        <div class="d-flex justify-content-start w-100 align-items-center">
                                            <!-- <img class="meeting-avatar" src="@/assets/px/Group.png" alt=""> -->
                                            <span class="material-symbols-rounded border member-icon"> person </span>
                                            <span class="ml-3 meeting-name">{{ attendee.name }}</span>
                                        </div>
                                        <div class="d-flex">
                                            
                                            <div class="mx-3 icon-mute-unmute d-flex justify-content-center align-items-center" :id="attendee.id + 'mute'" @click='toggleAttendeeMute(attendee.id)'>
                                                <span class="material-icons" style="font-size: 20px;"> {{ getMicIcon(attendee.muted) }} </span>
                                            </div>
                                            <div class="mx-3 icon-mute-unmute d-flex justify-content-center align-items-center" :id="attendee.id + 'kick'" @click='removeAttendee(attendee)'>
                                                <span class="material-icons-outlined" style="font-size: 20px;"> logout </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start py-5">
                            <div class="d-flex mt-2">
                                <div class="mute-btns" @click='muteAllAttendees'>
                                    <div class="d-flex p-2">
                                        <span class="material-icons"> mic_off </span>
                                        <span class="">Mute all</span>
                                    </div>
                                </div>
                                <div class="mute-btns" style="margin-left: 30px" @click='unmuteAllAttendees'>
                                    <div class="d-flex p-2">
                                        <span class="material-icons-outlined"> mic </span>
                                        <span class="">Unmute all</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </template>
            
        <!-- </div> -->
        <BaseModal ref='settingsModal'>
            <template #content='{ close }'>
                <h1>Device Setup</h1>
                <Dropdown
                    class='bottom-15 block'
                    label='Microphone'
                    name='Microphone'
                    id='audioInputDropdown'
                    ref='audioInputDropdown'
                    :options='audioInputDeviceOptions'
                    v-model='selected.audioInputDeviceId'
                    :disableEmpty='true'
                />
                <Dropdown
                    class='bottom-15 block'
                    label='Speakers'
                    name='Speakers'
                    id='audioOutputDropdown'
                    ref='audioOutputDropdown'
                    :options='audioOutputDeviceOptions'
                    v-model='selected.audioOutputDeviceId'
                    :disableEmpty='true'
                />
                <Dropdown
                    class='bottom-30 block'
                    label='Camera'
                    name='Camera'
                    id='videoInputDropdown'
                    ref='videoInputDropdown'
                    :options='videoInputDeviceOptions'
                    v-model='selected.videoInputDeviceId'
                    :disableEmpty='true'
                />

                <video
                    id='video-preview'
                    class='fullwidth bottom-20 radius-5'
                    style='transform: rotateY(180deg); position: relative!important;' autoplay='true'/>

                <div class='align-right'>
                    <button class='secondary' @click='close'>Cancel</button>
                    <button class='primary' @click='submitSettings'>Confirm</button>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script>
    /*
     * This was built from the use cases in https://github.com/aws/amazon-chime-sdk-js and https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/03_API_Overview.md
     *   The first github link above also contains other helpful links and docs about the chime js sdk.
     *   Especially:
     *      - an API overview that is similar to the use cases with some differences:
     *        https://aws.github.io/amazon-chime-sdk-js/modules/apioverview.html
     *   Some TODOs:
     *      - TODO Add background blur:
     *        https://aws.github.io/amazon-chime-sdk-js/modules/backgroundfilter_video_processor.html
     *      - TODO Smart video sending to allow for up to 250 video participants (only 25 are seen):
     *        https://aws.amazon.com/blogs/business-productivity/amazon-chime-sdk-smart-video-sending-demo/
     *      - TODO Make kicking happen faster all the time.
     *         Use the data message system to notify the to be kicked attendee that they have been kicked and cause them to leave the meeting.
     *         Must verify the legitimacy of sender, perhaps by using the senders attendee id to get attendee details and checking the given id against the
     *         counselor list of the appointment.
     *         https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/03_API_Overview.md#9-send-and-receive-data-messages-optional
     */

    import {
        ConsoleLogger,
        DefaultDeviceController,
        DefaultMeetingSession,
        LogLevel,
        MeetingSessionConfiguration,
        MeetingSessionStatusCode,
        DefaultActiveSpeakerPolicy,
        DefaultModality,
        DefaultBrowserBehavior,
        VoiceFocusDeviceTransformer
    } from 'amazon-chime-sdk-js';
    import { chime, appts, invoices, events, apptLog } from '@/util/apiRequests';
    import { ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import dayjs from '@/util/dayjs';
    import BaseModal from '../general/modals/BaseModal';
    import Loading from '@/components/general/loading/loading.vue';

    const DATA_MESSAGE_TOPIC_MUTE = 'mute';
    const DATA_MESSAGE_DATA_MUTE_ALL = 'all';

    const MAX_NUM_VIDEO_ELEMENTS = 25; // Chime supports up to 25 video participants in a meeting

    const DEFAULT_VIDEO_ELEMENT_SHADOW = '0 0 0 0 #6bd8bf'; //rgba(0, 0, 255, 0)
    const ACTIVE_SPEAKER_VIDEO_ELEMENT_SHADOW = '0 0 0 0 #6bd8bf';

    class Attendee {
        id; // chime attendee id
        encoded_user_id; // "u" for user or "c" for client followed by their db id. For example: "c12"
        name;
        muted;
        volume = 0;

        constructor(id, encoded_user_id = '-1', name = 'Guest', muted = false) {
            this.id = id;
            this.name = name;
            this.encoded_user_id = encoded_user_id;
            this.muted = muted;
        }
    }

    export default {
        name: 'VideoInBrowser',
        components: { ValidationObserver, BaseModal, Loading },
        props: {
            leaving: {
                // leaving is in the context of a route change. Used to know when to tear down the chime sdk
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                click: undefined,
                meetingPoll: null,
                userInLobby: false,
                userInMeeting: false,
                userReadyToJoin: false,
                unscheduledMeeting: false,
                userIsHost: false,
                selfMuted: false,
                localVideoStopped: true, // Start the meeting without local video
                contentSharing: false,
                receivingContentShareCount: 0, // Used to disable the start content share button if there are already two
                meetingSession: null, // IMPORTANT - comes from chime sdk, used for all chime sdk calls. When not null, we need to wait about 300 ms before unmounting the component to allow for proper tear down.
                audioInputDevices: [],
                audioOutputDevices: [],
                videoInputDevices: [],
                selected: {
                    audioInputDeviceId: null,
                    audioOutputDeviceId: null,
                    videoInputDeviceId: null
                },
                currentlySetDevice: {
                    audioInput: null,
                    audioOutput: null,
                    videoInput: null
                }, // These values should always reflect the current devices being used by the meetingSession
                videoElementIds: [], // Order corresponds to order on UI. 0 is featured. Video element ids are attendeeIds and get added and removed when attendees join or leave. Each video element id must have a tileId bound to it.
                attendeePresence: [],
                attendees: {}, // Index with attendee id to get the attendee object
                appointments: [],
                startedAppointment: null, // When an appointment is started, this should be filled with the appointment
                waitList: {}, // key: id, value: waitListMember
                activeSpeakerAttendeeId: null, // Used as a flag to know if there is a new active speaker
                activeSpeakerDefaultBorderTimeoutId: null,
                readyToJoinIntervalId: null,
                waitListGetterIntervalId: null,
                unscheduledMeetingName: '',
                payBeforeTelehealth: true,
                defaultBrowserBehavior: null,
                isFullScreen: '',
                voice_transformer : null,
                voice_focus_device: null,
                voice_focus_supported: false,
                voice_focus_on: false,
                observer: {
                    audioVideoDidStartConnecting: (reconnecting) => {
                        if (reconnecting) {
                            // e.g. the WiFi connection is dropped.
                            this.$toasted.info('Attempting to reconnect...');
                        } else {
                            // TODO: Show a connecting popup
                        }
                    },
                    audioVideoDidStart: () => {
                        this.$toasted.info('You joined the meeting');
                    },
                    // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
                    videoTileDidUpdate: async (tileState) => {
                        const landscape = tileState.videoStreamContentHeight < tileState.videoStreamContentWidth;
                        // Ignore a tile without attendee ID and other attendee's tile.
                        if (tileState.boundAttendeeId && tileState.localTile) {
                            // The user
                            this.$store.state.set_chime_meeting_session.audioVideo.bindVideoElement(
                                tileState.tileId,
                                await this.acquireVideoElement(tileState.boundAttendeeId, landscape)
                            );
                        }

                        // Ignore a tile without attendee ID, a local tile (your video), and a content share.
                        if (tileState.boundAttendeeId && !tileState.localTile && !tileState.isContent) {
                            // others
                            this.$store.state.set_chime_meeting_session &&
                            this.$store.state.set_chime_meeting_session.audioVideo.bindVideoElement(
                                tileState.tileId,
                                await this.acquireVideoElement(tileState.boundAttendeeId, landscape)
                            );
                        }

                        if (tileState.boundAttendeeId && tileState.isContent) {
                            const yourAttendeeId = this.$store.state.set_chime_meeting_session.configuration.credentials.attendeeId;

                            // tileState.boundAttendeeId is formatted as "attendee-id#content".
                            const boundAttendeeId = tileState.boundAttendeeId;

                            // Get the attendee ID from "attendee-id#content".
                            const baseAttendeeId = new DefaultModality(boundAttendeeId).base();
                            if (baseAttendeeId !== yourAttendeeId && tileState.active) {
                                this.receivingContentShareCount++;
                                this.$cl('Someone else started sharing their screen');
                            }
                            this.$store.state.set_chime_meeting_session &&
                            this.$store.state.set_chime_meeting_session.audioVideo.bindVideoElement(
                                tileState.tileId,
                                await this.acquireVideoElement(tileState.boundAttendeeId, landscape)
                            );
                        }
                    },
                    videoTileWasRemoved: (tileId) => {
                        this.$cl(`Tile ${tileId} was removed.`);
                    },
                    audioVideoDidStop: (sessionStatus) => {
                        const sessionStatusCode = sessionStatus.statusCode();
                        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
                            /*
                      - You called meetingSession.audioVideo.stop().
                      - When closing a browser window or page, Chime SDK attempts to leave the session.
                    */
                            this.$toasted.info('You left the meeting');
                        } else if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
                            /*
                      - You (or someone else) have called the DeleteMeeting API action in your server application.
                      - You attempted to join a deleted meeting.
                      - No audio connections are present in the meeting for more than five minutes.
                      - Fewer than two audio connections are present in the meeting for more than 30 minutes.
                      - Screen share viewer connections are inactive for more than 30 minutes.
                      - The meeting time exceeds 24 hours.
                      See https://docs.aws.amazon.com/chime/latest/dg/mtgs-sdk-mtgs.html for details.
                    */
                            this.$toasted.info('The meeting has ended');
                            this.leaveMeeting();
                        } else if (sessionStatusCode === MeetingSessionStatusCode.SignalingBadRequest) {
                            /*
                      - The host kicked you.
                     */
                            this.$toasted.info('You have been kicked from the meeting');
                            this.leaveMeeting();
                        } else {
                            this.$cl('Stopped with a session status code: ', sessionStatusCode);
                            // this.leaveMeeting();
                        }
                    },
                    contentShareDidStart: () => {
                        this.contentSharing = true;
                        this.$cl('Screen share started');
                    },
                    contentShareDidStop: () => {
                        // Chime SDK allows 2 simultaneous content shares per meeting.
                        // This method will be invoked if two attendees are already sharing content
                        // when you call startContentShareFromScreenCapture or startContentShare.
                        // Also invoked when you stop sharing your content.
                        this.contentSharing = false;
                        this.$cl('Screen share stopped');
                    }
                }, // These are attached to the meeting session
                deviceChangeObservers: {
                    audioInputsChanged: (freshAudioInputDeviceList) => {
                        this.audioInputDevices = freshAudioInputDeviceList;
                    },
                    audioOutputsChanged: (freshAudioOutputDeviceList) => {
                        this.audioOutputDevices = freshAudioOutputDeviceList;
                    },
                    videoInputsChanged: (freshVideoInputDeviceList) => {
                        this.videoInputDevices = freshVideoInputDeviceList;
                    }
                },
                activeSpeakerSettings: {
                    speakerWeight: 0.8,
                    cutoffThreshold: 0,
                    silenceThreshold: 0.4,
                    takeoverRate: 0.8
                },
                testing: false,
                events: [],
                isStartButtonDisabled: false,
                apptSessionId: ''
            };
        },
        async created() {
            this.$store.commit('persist_chime_meeting_id', 'getlocalonreload');
            await this.initPage();

        },
        async beforeDestroy() {
            
        },
        methods: {
            async initPage() {
                this.initFlags();
                this.appointments = await this.getTodayAppointments();
                this.events = await this.getTodayEvents();
                this.appointments = [...this.appointments, ...this.events];
                this.defaultBrowserBehavior = new DefaultBrowserBehavior();
                let startvideo = false;
                if (this.$store.state.chime_meeting_id?.localVideoStopped === false) {
                    startvideo = true;
                }
                if (this.$store.state.chime_meeting_id) {
                    this.userInMeeting = this.get_chime_persist('userInMeeting', this.userInMeeting);
                    this.userInLobby = this.get_chime_persist('userInLobby', this.userInLobby);
                    this.userReadyToJoin = this.get_chime_persist('userReadyToJoin', this.userReadyToJoin);
                    this.unscheduledMeeting = this.get_chime_persist('unscheduledMeeting', this.unscheduledMeeting);
                    this.userIsHost = this.get_chime_persist('userIsHost', this.userIsHost);
                    this.localVideoStopped = this.get_chime_persist('localVideoStopped', this.localVideoStopped);

                    const request_token = this.get_chime_persist('requestToken')
                    if (request_token) {
                        await this.joinLobby(request_token)
                    }

                    if ( startvideo) {
                        await this.startLocalVideo();
                    }
                }
                this.isStartButtonDisabled = false;
            },
            initFlags() {
                this.userInMeeting = false;
                this.userInLobby = false;
                this.userReadyToJoin = false;
                this.unscheduledMeeting = false;
                this.userIsHost = false;
                this.contentSharing = false;
                this.receivingContentShareCount = 0;
                this.localVideoStopped = true;
            },
            async getTodayAppointments() {
                let appointments;
                appointments = await this.getUserTodayAppointments();
                appointments = this.filterAppointments(appointments);
                appointments = this.sortAppointments(appointments);
                appointments = await this.addInvitedNamesToAppointments(appointments);
                return appointments;
            },

            async getTodayEvents() {
                let events;

                events = await this.getUserTodayEvents();

                events = this.sortEvents(events);
                events = await this.addInvitedNamesToEvents(events);
                return events;
            },
            async getUserTodayAppointments() {
                const currentDate = new Date();
                this.apptView = 0;
                const res = await this.$api.get(appts.getAll(), {
                    params: {
                        search: '',
                        u: this.user.id,
                        t: [1],
                        s: currentDate.toJSON(),
                        e: currentDate.toJSON()
                    }
                });

                if (res.status < 200 || res.status >= 300) {
                    // this.$toasted.error('Could not retrieve appointments');
                    return;
                }

                return res.data;
            },

            async getUserTodayEvents() {
                const currentDate = new Date();
                const res = await this.$api.get(events.teleEvents(), {
                    params: {
                        start: currentDate,
                        end: currentDate
                    }
                });

                if (res.status < 200 || res.status >= 300) {
                    // this.$toasted.error('Could not retrieve appointments');
                    return;
                }

                return res.data.map((event) => ({
                    ...event,
                    users: JSON.parse(event.users)
                }));


            },

            filterAppointments(appointments) {
                if (appointments?.length) {
                    const anHourAgo = dayjs().subtract(1, 'hour');
                    // Remove appointments that end before now and aren't telehealth
                    appointments = appointments.filter(
                        (appointment) =>
                            dayjs(appointment.dayt_appt_end).isBetween(anHourAgo, dayjs().endOf('day')) &&
                            appointment.telehealth
                    );
                    // If I'm a user and not a counselor on the appointment, remove it
                    if (!this.isClientLoggedIn) {
                        appointments = appointments.filter((appointment) => {
                            for (let counselor of appointment.counselors) {
                                if (counselor.id == this.user.id) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                    // If I'm a client and need to pay for an appointment, remove it from the list
                    else if (this.payBeforeTelehealth && appointments.length) {
                        appointments = appointments.filter((appointment) => appointment.amount_owed === 0);
                        if (appointments.length < 1) {
                            this.$toasted.info('Your appointment must be paid for before you can join.');
                        }
                    }
                }
                return appointments;
            },
            sortAppointments(appointments) {
                // Sort appointments in order of which comes first
                if (appointments?.length) {
                    appointments.sort((a, b) => {
                        if (dayjs(a.dayt_appt_start).isSame(b.dayt_appt_start)) {
                            return 0; // This shouldn't ever happen, but for the sake of completion, I included it anyways
                        } else if (dayjs(a.dayt_appt_start).isSameOrAfter(b.dayt_appt_start)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                }
                return appointments;
            },
            sortEvents(events) {
                // Sort appointments in order of which comes first
                if (events?.length) {
                    events.sort((a, b) => {
                        if (dayjs(a.dayt_appt_start).isSame(b.dayt_appt_start)) {
                            return 0; // This shouldn't ever happen, but for the sake of completion, I included it anyways
                        } else if (dayjs(a.dayt_appt_start).isSameOrAfter(b.dayt_appt_start)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                }
                return events;
            },
            async addInvitedNamesToAppointments(appointments) {
                let appts = appointments;
                if (appts?.length) {
                    appts = await Promise.all(
                        appointments.map(async (appointment) => {
                            const invitedNames = await this.getInvitedNamesFromAppointment(appointment);
                            return { ...appointment, invitedNames };
                        })
                    );
                }
                return appts;
            },
            async addInvitedNamesToEvents(events) {
                if (events?.length) {
                    events = await Promise.all(
                        events.map(async (event) => {
                            // const invitedNames = await this.getInvitedNamesFromAppointment(events);
                            const invitedNames = await this.getInvitedNamesFromEvent(event);
                            return { ...event, invitedNames };
                        })
                    );
                }
                return events;
            },
            async getInvitedNamesFromAppointment(appointment) {
                let invitedNames = [];
                if (!this.isClientLoggedIn) {
                    // Get a list of invited names. This doesn't exist for the client appts
                    appointment?.clients.forEach((client) =>
                        invitedNames.push({
                            name: this.combineFirstAndLastName(client.first_name, client.last_name),
                            invoiceId: client.invoice_id,
                            isClient: true
                        })
                    );
                    appointment?.counselors.forEach((counselor) => {
                        if (!counselor.host) invitedNames.push({ name: counselor.name, isClient: false });
                    });
                }

                invitedNames = await Promise.all(
                    invitedNames.map(async (invitedName) => {
                        if (invitedName.isClient) {
                            const { data } = await this.$api.get(invoices.getAmountOwed(invitedName.invoiceId));

                            return { ...invitedName, amountOwing: data.owed };
                        } else {
                            return invitedName;
                        }
                    })
                );

                return invitedNames;
            },

            async getInvitedNamesFromEvent(event) {

                let invitedNames = [];

                if (!this.isClientLoggedIn) {
                    //Add meeting host to invited Names
                    invitedNames.push({
                        name: event.users.meetingHost?.name,
                        isMeetingHost: true
                    });

                    event?.users.users.forEach((user) =>
                        invitedNames.push({
                            name: user.name,
                            isMeetingHost: false
                        })
                    );
                }
                return invitedNames;
            },
            async leaveMeeting() {
               
                if(this.isFullScreen)
                {
                    this.isFullScreen=false;
                }

                if (this.readyToJoinIntervalId) {
                    window.clearInterval(this.readyToJoinIntervalId);
                }
                if (this.waitListGetterIntervalId) {
                    window.clearInterval(this.waitListGetterIntervalId);
                }
                if (this.activeSpeakerDefaultBorderTimeoutId) {
                    window.clearTimeout(this.activeSpeakerDefaultBorderTimeoutId);
                }
                this.$store.state.set_chime_meeting_session && (await this.stopScreenContentShare());
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.startVideoInput(null));
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.startAudioInput(null));
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.chooseAudioOutput(null));
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.stopVideoInput());
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.stop());
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.removeObserver(this.observer));
                this.$store.state.set_chime_meeting_session &&
                (await this.$store.state.set_chime_meeting_session.audioVideo.removeDeviceChangeObserver(this.deviceChangeObservers));
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.unsubscribeFromActiveSpeakerDetector());
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.realtimeUnsubscribeToAttendeeIdPresence());
                this.$store.state.set_chime_meeting_session &&
                (await this.$store.state.set_chime_meeting_session.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
                    DATA_MESSAGE_TOPIC_MUTE
                ));
                this.stopSettingsVideoPreview();
                this.unsubscribeFromWaitList();
                const attendeeKeys = Object.keys(this.attendees);
                attendeeKeys.forEach(this.handleRemovedAttendee);
                await this.clear_chime_persist();
                await this.resetPageData();
            },
            unsubscribeFromWaitList() {
                if (this.readyToJoinIntervalId) {
                    window.clearInterval(this.readyToJoinIntervalId);
                }
                if (this.waitListGetterIntervalId) {
                    window.clearInterval(this.waitListGetterIntervalId);
                }
                if (this.activeSpeakerDefaultBorderTimeoutId) {
                    window.clearTimeout(this.activeSpeakerDefaultBorderTimeoutId);
                }
                window.clearInterval(this.waitListGetterIntervalId);
                window.clearInterval(this.readyToJoinIntervalId);
            },
            async resetPageData() {
                for (let i in this.selected) {
                    this.selected[i] = null;
                }
                for (let i in this.currentlySetDevice) {
                    this.currentlySetDevice[i] = null;
                }
                this.attendeePresence = [];
                this.attendees = {};
                this.waitList = {};
                this.startedAppointment = null;
                this.activeSpeakerAttendeeId = null;
                this.$store.state.set_chime_meeting_session = null;
                await this.initFlags();
            },
            async startUnscheduledMeeting() {
                const form = this.$refs.unscheduledMeetingForm;
                const isValid = await form.validate();

                if (!isValid) {
                    //this.$toasted.error('Invalid meeting name');
                    return;
                }

                this.isStartButtonDisabled = true;

                this.unscheduledMeeting = true;
                this.set_chime_persist('unscheduledMeeting', this.unscheduledMeeting);
                await this.joinLobby(this.unscheduledMeetingName);

                this.isStartButtonDisabled = false;
            },
            async startAppointment(appointment) {
                this.startedAppointment = appointment;
                // if "appt" is event
                if (appointment.event) {
                    await this.joinLobby(appointment.id);
                    return;
                }

                // if regular tele appt
                await this.joinLobby(appointment.group_uuid);
            },
            async joinLobby(requestToken) {
                if (!requestToken || requestToken === -1) {
                    throw new Error('Invalid request token in joinLobby');
                }
                this.set_chime_persist('requestToken', requestToken)
                await this.initializeMeetingSession(requestToken);

                // Important to determine who the host is as soon as we initialize the meeting session.
                await this.determineWhoIsHost(this.$store.state.set_chime_meeting_session.configuration.meetingId);

                await this.setupDevices();
                this.addObservers();

                if (this.unscheduledMeeting) {
                    // For unscheduled meetings, skip the wait list
                    await this.joinMeeting();
                } else {
                    this.userInLobby = true;
                    this.set_chime_persist('userInLobby', this.userInLobby);
                    if (!this.userIsHost) {
                        await this.joinWaitList();
                    } else {
                        await this.subscribeToWaitList();
                        await this.joinMeeting();
                    }
                }
                await this.pick_the_right_mike();
                return 1
            },
            async initializeMeetingSession(requestToken) {
                try {
                    const meetingResponse = await this.getCreateMeetingResponse(
                        requestToken
                    ); /* The response from the CreateMeeting API action */
                    const meeting_id = meetingResponse.Meeting.MeetingId;
                    this.set_chime_persist('meeting_id', meeting_id)
                    let attendeeResponse = meetingResponse.Attendee;

                    // Create Appt Session Log - Chime Call History
                    this.createApptLog();
                    this.updateApptCapturedTime();

                    // this should now be redundant
                    if (!attendeeResponse) {
                        attendeeResponse = await this.getCreateAttendeeResponse(
                            meeting_id
                        ); /* The response from the CreateAttendee or BatchCreateAttendee API action */
                    }

                        const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
                    const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
                    const deviceController = new DefaultDeviceController(logger,{ enableWebAudio: true});

                    this.voice_focus_supported = await VoiceFocusDeviceTransformer.isSupported();
                    this.$cl('VOICE FOCUS SUPPORT = ' + this.voice_focus_supported);
                    if (this.voice_focus_supported) {
                        const spec = {
                            name: 'ns_es',
                        };
                        this.voice_focus_on = true;
                        try {
                            this.voice_transformer = await VoiceFocusDeviceTransformer.create(); // spec - this spec ns_es caused an error
                            // this.voice_focus_supported = this.voice_transformer.isSupported();
                        } catch (e) {
                            // Will only occur due to invalid input or transient errors (e.g., network).
                            this.voice_focus_supported = false;
                            this.voice_focus_on = false;
                        }
                    } else {
                        this.voice_focus_on = false;
                    }
                    this.$store.state.set_chime_meeting_session = new DefaultMeetingSession(configuration, logger, deviceController);
                } catch (e) {
                    //this.$toasted.error('Could not join meeting');
                    return;
                }
            },
            async getCreateMeetingResponse(requestToken) {
                return this.getUserCreateMeetingResponse(requestToken);
            },
            async getUserCreateMeetingResponse(requestToken) {
                const response = await this.$api.get(chime.createMeeting(requestToken));
                return response.data;
            },
            async getCreateAttendeeResponse(meeting_id) {
                return this.getUserCreateAttendeeResponse(meeting_id);
            },
            async getUserCreateAttendeeResponse(meeting_id) {
                const response = await this.$api.get(chime.createAttendee(meeting_id, this.encodedUserId));
                return response.data;
            },
            async determineWhoIsHost(meeting_id) {
                if (!this.isClientLoggedIn) {
                    // Client will never be host
                    let attendees = await this.listAttendees(meeting_id);

                    //if tele event
                    if (this.startedAppointment && this.startedAppointment.event) {
                        if (this.startedAppointment.users.meetingHost.id == this.$store.state.user.id) {
                            this.userIsHost = true;
                            this.set_chime_persist('userIsHost', this.userIsHost)
                            return;
                        }
                    }
                    // if tele appt
                    if (this.startedAppointment && !this.startedAppointment.event) {
                        for (let counselor of this.startedAppointment.counselors) {
                            if (counselor.id === this.user.id) {
                                this.userIsHost = true;
                                this.set_chime_persist('userIsHost', this.userIsHost)
                            }
                        }
                    } else if (this.noOtherUsers(attendees)) {
                        // if i'm the only user in the list, make me the host
                        this.userIsHost = true;
                        this.set_chime_persist('userIsHost', this.userIsHost)
                    }
                }
            },
            async listAttendees(meeting_id) {
                if (!this.isClientLoggedIn) {
                    try {
                        const res = await this.$api.get(chime.getListOfAttendees(meeting_id));
                        return res.data.Attendees;
                    } catch (err) {
                        console.error(err);
                    }
                }
                return null;
            },
            noOtherUsers(attendees) {
                for (let attendee of attendees) {
                    if (attendee.ExternalUserId.includes('u') && attendee.ExternalUserId !== this.encodedUserId) {
                        return false;
                    }
                }
                return true;
            },
            async setupDevices() {
                await this.getDevices();
                await this.initializeDefaultDevices();
            },
            async getDevices() {
                if (this.$store.state.set_chime_meeting_session) {
                    this.audioInputDevices = await this.$store.state.set_chime_meeting_session.audioVideo.listAudioInputDevices();
                    this.audioOutputDevices = await this.$store.state.set_chime_meeting_session.audioVideo.listAudioOutputDevices();
                    this.videoInputDevices = await this.$store.state.set_chime_meeting_session.audioVideo.listVideoInputDevices();
                } else {
                    throw Error('There is no meeting session');
                }
            },
            async initializeDefaultDevices() {
                await this.initializeDefaultVideoDevice();
                await this.initializeDefaultAudioInputDevice();
                await this.initializeDefaultAudioOutputDevice();
            },
            async initializeDefaultVideoDevice() {
                const defaultVideoDevice = await this.getDefaultVideoDevice();
                if (defaultVideoDevice) {
                    this.selected.videoInputDeviceId = defaultVideoDevice.deviceId;
                    this.set_chime_persist('selected_videoInputDeviceId', this.selected.videoInputDeviceId);
                    await this.setVideoInputDevice(defaultVideoDevice);
                }
            },
            async getDefaultVideoDevice() {
                let device = null;

                if (this.videoInputDevices.length > 0) {
                    device = this.videoInputDevices[0];
                }

                return device;
            },
            async setVideoInputDevice(device) {
                if (this.$store.state.set_chime_meeting_session) {
                    if (device) {
                        try {
                            await this.$store.state.set_chime_meeting_session.audioVideo.startVideoInput(device);
                            this.currentlySetDevice.videoInput = device;
                        } catch (e) {
                            console.warn('Your camera is not available. Quit anything using it and rejoin.');
                            this.currentlySetDevice.videoInput = null;
                        }
                    }
                } else {
                    throw Error('There is no meeting session.');
                }
            },
            async initializeDefaultAudioInputDevice() {
                const defaultAudioDevice = await this.getDefaultAudioInputDevice();
                this.selected.audioInputDeviceId = defaultAudioDevice.deviceId;
                await this.setAudioInputDevice(defaultAudioDevice);
                return 1
            },
            async getDefaultAudioInputDevice() {
                let device = null;

                if (this.audioInputDevices.length > 0) {
                    device = this.audioInputDevices[0];
                }

                return device;
            },
            async setAudioInputDevice(device) {
                const deviceToUse = this.voice_focus_device || device; // maybe the transformer when created with default device need to be selected here - and NOT the select option at all.
                await this.focus_voice();
                if (this.voice_focus_device) {
                    this.$cl('Amazon Voice Focus enabled');
                } else {
                    this.$cl('Amazon Voice Focus NO DEVICE');
                }
                if (this.$store.state.set_chime_meeting_session) {
                    this.currentlySetDevice.audioInput = deviceToUse;
                    if (typeof this.$store.state.set_chime_meeting_session.audioVideo.startAudioInput !== "undefined") {
                        await this.$store.state.set_chime_meeting_session.audioVideo.startAudioInput(deviceToUse);
                    } else {
                        await this.clear_meeting();
                    }

                }
            },
            async initializeDefaultAudioOutputDevice() {
                let defaultAudioDeviceId = null;
                if (this.defaultBrowserBehavior.supportsSetSinkId()) {
                    let defaultAudioDevice = await this.getDefaultAudioOutputDevice();
                    defaultAudioDeviceId = defaultAudioDevice.deviceId;
                }
                this.selected.audioOutputDeviceId = defaultAudioDeviceId;
                await this.setAudioOutputDevice(defaultAudioDeviceId);
            },
            async getDefaultAudioOutputDevice() {
                let device = null;

                if (this.audioOutputDevices.length > 0) {
                    device = this.audioOutputDevices[0];
                }

                return device;
            },
            async setAudioOutputDevice(deviceId) {
                // Always set this by the device Id
                if (this.$store.state.set_chime_meeting_session && (this.defaultBrowserBehavior.supportsSetSinkId() || deviceId === null)) {
                    this.currentlySetDevice.audioOutput = this.findDeviceByDeviceId(this.audioOutputDevices, deviceId);
                    await this.$store.state.set_chime_meeting_session.audioVideo.chooseAudioOutput(deviceId); // Calling this with null will set the audio output device to default
                    await this.bindAudio();
                }
            },
            addObservers() {
                this.$store.state.set_chime_meeting_session.audioVideo.addContentShareObserver(this.observer);
                this.$store.state.set_chime_meeting_session.audioVideo.addObserver(this.observer);
                this.$store.state.set_chime_meeting_session.audioVideo.addDeviceChangeObserver(this.deviceChangeObservers);
            },
            async acquireVideoElement(attendeeId, landscape) {
                const videoElementId = attendeeId;
                if (this.videoElementIds.length >= MAX_NUM_VIDEO_ELEMENTS) {
                    throw new Error('no video element is available');
                }
                if (!this.videoElementIds.includes(videoElementId)) {
                    // If this id isn't in our array, add it. This can happen when multiple attendees are in a meeting you're joining
                    await this.addVideoElement(videoElementId);
                    await this.$nextTick();
                }
                const el = document.getElementById(videoElementId); // If this is null, it will cause problems when chime tries to bind to it.
                el.style.objectFit = landscape ? 'cover' : 'contain';
                // el.controls = true;
                // el.controlsList =
                //     'noremoteplayback noplay nopause nosearch nopaused noplaybackrate norewind noreplay norewind noplaydisablenone noplaydisablesmall noplaydisablesmall fullscreen nocontrols';
                return el; // Return the video element associated with the id
            },
            async addVideoElement(videoElementId) {
                if (videoElementId && !this.videoElementIds.includes(videoElementId)) {
                    const positionForId = this.determinePositionForVideoElementId(videoElementId, this.videoElementIds);
                    const copy = [...this.videoElementIds]
                    copy.splice(positionForId, 0, videoElementId);
                    this.$set(this, 'videoElementIds', copy);
                    if (videoElementId === this.selfAttendeeId) {
                        this.mirrorElement(videoElementId); // This is a work around so that the user doesn't see the tile rotating when the camera turns on
                    }
                }
            },
            determinePositionForVideoElementId(attendeeId, videoElementIds) {
                // Figure out where in the list the attendee tile should be placed
                let index = this.contentShareCount; // Content shares should always have the lowest indexes;
                if (!attendeeId.includes('#content')) {
                    if (
                        this.selfAttendeeId !== attendeeId &&
                        this.attendees[attendeeId] &&
                        this.attendees[attendeeId].encoded_user_id.includes('u')
                    ) {
                        index += this.countUsersFromVideoElementIds(videoElementIds);
                    } else {
                        index = videoElementIds.length;
                    }
                }
                return index;
            },
            countUsersFromVideoElementIds(videoElementIds) {
                let count = 0;
                for (let id of videoElementIds) {
                    if (
                        this.selfAttendeeId !== id &&
                        this.attendees[id] &&
                        this.attendees[id].encoded_user_id.includes('u')
                    ) {
                        count++;
                    }
                }
                return count;
            },
            mirrorElement(videoElementId) {
                const el = document.getElementById(videoElementId);
                if (el) {
                    el.style.transform = 'rotateY(180deg)';
                }
            },
            featureAttendee(attendeeId) {
                let start = 0;
                if (this.contentShareCount > 0 && !attendeeId.includes('#content')) {
                    start = this.contentShareCount; // Content shares should always have the lowest indexes
                }
                const indexOfId = this.videoElementIds.indexOf(attendeeId);
                const idsVideoElementId = this.videoElementIds.splice(indexOfId, 1);
                this.videoElementIds.splice(start, 0, ...idsVideoElementId);
            },
            async joinWaitList() {
                if (!this.userReadyToJoin) {
                    this.userReadyToJoin = true;
                    this.set_chime_persist('userReadyToJoin', this.userReadyToJoin);
                    this.sendJoinWaitListMessage();
                    this.setupCheckAdmittanceInterval();
                }
            },
            sendJoinWaitListMessage() {
                this.sendUserJoinWaitListMessage();

            },
            async sendUserJoinWaitListMessage() {
                //console.warn("Sending ready to join message");
                try {
                    let name = this.user.name;
                    await this.$api.put(
                        chime.addToWaitList(this.$store.state.set_chime_meeting_session.configuration.meetingId, this.encodedUserId),
                        { name: name }
                    );
                } catch (err) {
                    console.error(err);
                }
            },
            setupCheckAdmittanceInterval() {
                let intervalFunction = this.checkUserAdmittanceOnWaitList;
                this.readyToJoinIntervalId = window.setInterval(
                    intervalFunction.bind(this),
                    1500,
                    this.$store.state.set_chime_meeting_session.configuration.meetingId,
                    this.encodedUserId
                );
            },
            async checkUserAdmittanceOnWaitList(meetingId, encodedUserId) {
                try {
                    const res = await this.$api.get(chime.checkAdmittanceOnWaitList(meetingId, encodedUserId));
                    // if admitted, clear the interval and join the meeting
                    if (res.data.length > 0) {
                        if (res.data[0].admittance === 1) {
                            window.clearInterval(this.readyToJoinIntervalId);
                            await this.joinMeeting();
                        }
                    } else {
                        // Wait list was deleted or we were removed so go back to landing screen
                        window.clearInterval(this.readyToJoinIntervalId);
                        this.$toasted.info('You were removed from the wait list');
                        await this.leaveMeeting();
                    }
                } catch (err) {
                    console.error(err);
                    this.$toasted.error('Server error. Refresh the page and try again.');
                    await this.leaveMeeting();
                }
            },
            async subscribeToWaitList() {
                if (this.userIsHost) {
                    this.doHostWaitListSubscriptions();
                }
            },
            doHostWaitListSubscriptions() {
                this.unsubscribeFromWaitList();
                this.waitListGetterIntervalId = window.setInterval(
                    this.getWaitList.bind(this),
                    1500,
                    this.$store.state.set_chime_meeting_session.configuration.meetingId
                );
            },
            async getWaitList(meetingId) {
                if (!this.isClientLoggedIn) {
                    try {
                        const res = await this.$api.get(chime.getWaitList(meetingId));
                        this.waitList = res.data;
                    } catch (err) {
                        console.error(err);
                        this.$toasted.error('Server error. Refresh the page and try again.');
                        await this.leaveMeeting();
                    }
                }
            },
            async joinMeeting() {
                // TODO: Ensure that there is at least an audio output device available before starting the meeting session.
                await this.bindAudio();
                this.initializeSubscriptions();
                this.$store.state.set_chime_meeting_session.audioVideo.start();
                this.userInLobby = false;
                this.userInMeeting = true;
                this.set_chime_persist('userInLobby', this.userInLobby);
                this.set_chime_persist('userInMeeting', this.userInMeeting);
            },
            async startLocalVideo() {
                if (this.selected.videoInputDeviceId) {
                    await this.setVideoInputDeviceById(this.selected.videoInputDeviceId);
                    this.$store.state.set_chime_meeting_session.audioVideo.startLocalVideoTile();
                    this.localVideoStopped = false;
                    this.set_chime_persist('localVideoStopped', this.localVideoStopped);
                } else {
                    console.warn('Failed to start local video');
                }
            },
            async bindAudio() {
                const audioElement = document.getElementById('meetingAudioElement');
                try {
                    await this.$store.state.set_chime_meeting_session.audioVideo.bindAudioElement(audioElement);
                } catch (e) {
                    this.logger?.error(`Failed to bind audio element: ${e}`);
                }
            },
            initializeSubscriptions() {
                this.subscribeToActiveSpeakerDetector();
                this.subscribeToMuteDataMessage();
                this.subscribeToAttendeesPresenceTracker();
            },
            subscribeToActiveSpeakerDetector() {
                this.$store.state.set_chime_meeting_session.audioVideo.subscribeToActiveSpeakerDetector(
                    new DefaultActiveSpeakerPolicy(
                        this.activeSpeakerSettings.speakerWeight,
                        this.activeSpeakerSettings.cutoffThreshold,
                        this.activeSpeakerSettings.silenceThreshold,
                        this.activeSpeakerSettings.takeoverRate
                    ),
                    this.activeSpeakerCallback
                );
            },
            activeSpeakerCallback(attendeeIds) {
                if (attendeeIds.length) {
                    this.setActiveSpeakerBorder(attendeeIds[0]);
                    if (this.activeSpeakerAttendeeId !== attendeeIds[0]) {
                        this.activeSpeakerAttendeeId = attendeeIds[0];
                    }
                }
            },
            setActiveSpeakerVideo(attendeeId) {
                this.featureAttendee(attendeeId);
            },
            setActiveSpeakerBorder(attendeeId) {
                if (attendeeId) {
                    if (this.activeSpeakerAttendeeId && this.activeSpeakerAttendeeId !== attendeeId) {
                        // New most active speaker
                        // Set current active speaker's border to default
                        const oldVideoElement = document.getElementById(this.activeSpeakerAttendeeId);
                        if (oldVideoElement) {
                            oldVideoElement.style.boxShadow = DEFAULT_VIDEO_ELEMENT_SHADOW;
                        }
                    }
                    const videoElement = document.getElementById(attendeeId);
                    if (videoElement) {
                        videoElement.style.boxShadow = ACTIVE_SPEAKER_VIDEO_ELEMENT_SHADOW;
                        this.setTimeoutForActiveSpeakerDefaultBorder(videoElement);
                    }
                }
            },
            setTimeoutForActiveSpeakerDefaultBorder(element) {
                window.clearTimeout(this.activeSpeakerDefaultBorderTimeoutId);
                this.activeSpeakerDefaultBorderTimeoutId = window.setTimeout(() => {
                    element.style.boxShadow = DEFAULT_VIDEO_ELEMENT_SHADOW;
                }, 500);
            },
            subscribeToMuteDataMessage() {
                const muteHandler = (dataMessage) => {
                    if (!dataMessage.throttled) {
                        const decodedData = dataMessage.json();
                        const senderIsSelf = this.attendeeIdIsSelf(dataMessage.senderAttendeeId);
                        const all = decodedData?.id === DATA_MESSAGE_DATA_MUTE_ALL;
                        const messageIsToSelf = this.attendeeIdIsSelf(decodedData?.id);
                        if (!senderIsSelf && (all || messageIsToSelf)) {
                            decodedData.mute ? this.muteSelf() : this.unmuteSelf();
                        }
                    }
                };
                this.$store.state.set_chime_meeting_session.audioVideo.realtimeSubscribeToReceiveDataMessage(
                    DATA_MESSAGE_TOPIC_MUTE,
                    muteHandler
                );
            },
            attendeeIdIsSelf(attendeeId) {
                // Content shares are added as an attendee with the same attendee id as the sharer,
                // but with a #content at the end.
                return attendeeId.includes(this.selfAttendeeId);
            },
            subscribeToAttendeeMuteChanges(attendeeId) {
                const callback = (attendeeId, volume, muted) => {
                    // A null value for volume, muted and signalStrength field means that it has not changed.
                    if (muted === null) {
                        // muted state has not changed, ignore volume and signalStrength changes
                        return;
                    }
                    // mute state changed
                    if (this.attendeeIdIsSelf(attendeeId)) {
                        this.selfMuted = muted;
                    }
                    this.attendees[attendeeId].muted = muted;
                };

                this.$store.state.set_chime_meeting_session.audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback);
            },
            subscribeToAttendeesPresenceTracker() {
                const callback = (presentAttendeeId, present) => {
                    if (present) {
                        this.attendeePresence.push(presentAttendeeId);
                    } else {
                        const pos = this.attendeePresence.indexOf(presentAttendeeId);
                        this.attendeePresence.splice(pos, 1);
                    }
                };

                this.$store.state.set_chime_meeting_session.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);
            },
            subscribeToAttendeeVolumeIndicator(attendeeId) {
                const callback = (attendeeId, volume) => {
                    //console.warn(`AttendeeId = ${attendeeId}, volume = ${volume}`)
                    if (volume === null) {
                        return;
                    }
                    if (this.activeSpeakerAttendeeId === attendeeId) {
                        this.setActiveSpeakerBorder(attendeeId);
                    }
                    this.attendees[attendeeId].volume = volume;
                };

                this.$store.state.set_chime_meeting_session.audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback);
            },
            toggleVideo() {
                if (this.localVideoStopped) {
                    this.startLocalVideo();
                } else {
                    this.stopLocalVideo();
                }
            },
            stopLocalVideo() {
                this.$store.state.set_chime_meeting_session.audioVideo.stopVideoInput();
                this.mirrorElement(this.selfAttendeeId); // This is a work around so that the user doesn't see the tile rotating when the camera turns off
                this.localVideoStopped = true;
                this.set_chime_persist('localVideoStopped', this.localVideoStopped);
            },
            toggleSelfMute() {
                const muted = this.$store.state.set_chime_meeting_session.audioVideo.realtimeIsLocalAudioMuted();
                if (muted) {
                    this.unmuteSelf();
                } else {
                    this.muteSelf();
                }
            },
            unmuteSelf() {
                const unmuted = this.$store.state.set_chime_meeting_session.audioVideo.realtimeUnmuteLocalAudio();
                if (!unmuted) {
                    // See the realtimeSetCanUnmuteLocalAudio use case
                    this.$toasted.info('You cannot unmute yourself');
                }
            },
            muteSelf() {
                this.$store.state.set_chime_meeting_session.audioVideo.realtimeMuteLocalAudio();
            },
            getOptionsFromDevicesList(list) {
                let options = [];
                for (let device in list) {
                    let option = {
                        value: list[device].deviceId,
                        text: this.formatDeviceLabel(list[device].label)
                    };
                    // Sometimes there the default device appears twice, once with "Default -" and once without.
                    if (options.length > 1 && options[0].text.endsWith(option.text)) {
                        // Remove duplicates of default
                        continue;
                    }
                    options.push(option);
                }
                return options;
            },
            formatDeviceLabel(label) {
                return label.split(/[(]\S+[:]\S+[)]/)[0].trimEnd();
            },
            findDeviceByDeviceId(devices, deviceId) {
                for (let i in devices) {
                    if (devices[i].deviceId === deviceId) {
                        return devices[i];
                    }
                }
                return null;
            },
            getDifference(a, b) {
                let hash = this.getHash(b);
                let diff = [];
                for (let i = 0; i < a.length; i++) {
                    let value = a[i];
                    if (!hash[value]) {
                        diff.push(value);
                    }
                }
                return diff;
            },
            getHash(array) {

                let hash = {};
                for (let i = 0; i < array.length; i++) {
                    hash[array[i]] = true;
                }
                return hash;
            },
            async removeAttendee(attendee) {
                if (!this.isClientLoggedIn && attendee.encoded_user_id) {
                    const res = await this.$api.patch(
                        chime.kickAttendee(
                            this.$store.state.set_chime_meeting_session.configuration.meetingId,
                            attendee.id,
                            attendee.encoded_user_id
                        )
                    );
                    if (res.status < 200 || res.status >= 300) {
                        //this.$toasted.error('Internal server error');
                        return;
                    }
                } else {
                    console.warn('Cannot kick attendee.');
                }
            },
            muteAllAttendees() {
                if (this.userIsHost) {
                    const data = {
                        id: DATA_MESSAGE_DATA_MUTE_ALL,
                        mute: true
                    };
                    this.$store.state.set_chime_meeting_session.audioVideo.realtimeSendDataMessage(DATA_MESSAGE_TOPIC_MUTE, data);
                }
            },
            unmuteAllAttendees() {
                if (this.userIsHost) {
                    const data = {
                        id: DATA_MESSAGE_DATA_MUTE_ALL,
                        mute: false
                    };
                    this.$store.state.set_chime_meeting_session.audioVideo.realtimeSendDataMessage(DATA_MESSAGE_TOPIC_MUTE, data);
                }
            },
            toggleAttendeeMute(attendeeId) {
                if (this.userIsHost) {
                    const data = {
                        id: attendeeId,
                        mute: !this.attendees[attendeeId].muted
                    };
                    this.$store.state.set_chime_meeting_session.audioVideo.realtimeSendDataMessage(DATA_MESSAGE_TOPIC_MUTE, data);
                }
            },
            combineFirstAndLastName(firstName, lastName) {
                return (firstName ? firstName + ' ' : '') + (lastName ? lastName : '');
            },
            async handleNewAttendee(attendeeId) {
                await this.addNewAttendee(attendeeId);
                this.subscribeToAttendeeMuteChanges(attendeeId);
                this.subscribeToAttendeeVolumeIndicator(attendeeId);
            },
            async addNewAttendee(attendeeId) {
                const details = await this.getAttendeeDetails(attendeeId);
                let attendee = new Attendee(attendeeId, details?.encoded_user_id, details?.name);
                await this.$set(this.attendees, attendeeId, attendee); // Vue won't recognize the change without this.$set
                if (!this.videoElementIds.includes(attendeeId)) {
                    // Add attendee then call addVideoElement
                    await this.addVideoElement(attendeeId);
                }

            },
            async getAttendeeDetails(attendeeId) {
                let attendeeDetails;
                if (!this.isClientLoggedIn) {
                    try {
                        attendeeDetails = await this.$api.get(
                            chime.getAttendeeDetails(this.$store.state.set_chime_meeting_session.configuration.meetingId, attendeeId)
                        );
                    } catch (e) {
                        console.error(e);
                    }
                }
                if (attendeeId.includes('#content') && attendeeDetails.data.name) {
                    attendeeDetails.data.name += ' (Content Share)';
                }
                return attendeeDetails.data;
            },
            handleRemovedAttendee(attendeeId) {
                this.unsubscribeToAttendeeVolumeIndicator(attendeeId);
                this.videoElementIds = this.videoElementIds.filter((id) => attendeeId !== id);
                if (attendeeId.includes('#content') && !attendeeId.includes(this.selfAttendeeId)) {
                    this.receivingContentShareCount--;
                }
                delete this.attendees[attendeeId];
                this.attendees = { ...this.attendees }; // Vue won't recognize the change unless this is done
                if (!this.isClientLoggedIn) {
                    // TODO: BUG If there are three users in a meeting and the host leaves, who becomes the new host? No one?
                    if (this.isUserTheOnlyUserInMeeting()) {
                        this.makeUserHost();
                    }
                }
            },
            unsubscribeToAttendeeVolumeIndicator(attendeeId) {
                // This single call unsubscribes all volume indicator callbacks associated with the attendeeId
                this.$store.state.set_chime_meeting_session.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
            },
            isUserTheOnlyUserInMeeting() {
                let attendees = this.attendees;
                let attendeesKeysFiltered = Object.keys(attendees).filter(
                    (attendeeId) =>
                        !this.attendeeIdIsSelf(attendeeId) || attendees[attendeeId].encoded_user_id.includes('c')
                );
                if (attendeesKeysFiltered.length < 1) {
                    return true;
                } else {
                    return false;
                }
            },
            makeUserHost() {

                // for events
                if (!this.isClientLoggedIn && this.startedAppointment?.event) {
                    if (this.startedAppointment.users.meetingHost.id == this.$store.state.user.id) {
                        this.userIsHost = true;
                        this.set_chime_persist('userIsHost', this.userIsHost)
                        this.subscribeToWaitList();
                    }
                    return;
                }

                // for appts
                if (!this.isClientLoggedIn && !this.startedAppointment?.event) {
                    this.userIsHost = true;
                    this.set_chime_persist('userIsHost', this.userIsHost)
                    this.subscribeToWaitList();
                }
            },
            getMutedText(muted) {
                return muted ? 'Muted' : 'Unmuted';
            },
            getMicIcon(muted, attendee = false) {
                return muted ? 'mic_off' : attendee ? '' : 'mic';
            },
            getVolumeDisplayText(volume) {
                let adjustedVolume = Math.round(volume * 5);
                let display = '';
                for (let i = 0; i < adjustedVolume; i += 1) {
                    display += '|';
                }
                return display;
            },
            async admitFromWaitList(waitListMemberId) {
                if (this.userIsHost && !this.isClientLoggedIn) {
                    try {
                        const res = await this.$api.patch(
                            chime.admitFromWaitList(this.$store.state.set_chime_meeting_session.configuration.meetingId, waitListMemberId)
                        );
                        return res;
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            async removeFromWaitList(waitListMemberId) {
                if (this.userIsHost && !this.isClientLoggedIn) {
                    try {
                        const res = await this.$api.patch(
                            chime.removeFromWaitList(this.$store.state.set_chime_meeting_session.configuration.meetingId, waitListMemberId)
                        );
                        return res;
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            makeArrayIntoListText(array, prop = '') {
                if (prop === '') {
                    let listText = '';
                    if (array && array.length > 0) {
                        array.forEach((name, index) => {
                            listText = listText + ' ' + name + (index === array.length - 1 ? '' : ',');
                        });
                    }
                    return listText;
                } else {
                    let listText = '';
                    if (array && array.length > 0) {
                        array.forEach((name, index) => {
                            listText = listText + ' ' + name[prop] + (index === array.length - 1 ? '' : ',');
                        });
                    }
                    return listText;
                }
            },
            async endMeeting() {
                if(this.isFullScreen==true)
                {
                    this.isFullScreen=false;
                }
                if (this.userIsHost && !this.isClientLoggedIn) {
                    if (this.readyToJoinIntervalId) {
                        window.clearInterval(this.readyToJoinIntervalId);
                    }
                    if (this.waitListGetterIntervalId) {
                        window.clearInterval(this.waitListGetterIntervalId);
                    }
                    if (this.activeSpeakerDefaultBorderTimeoutId) {
                        window.clearTimeout(this.activeSpeakerDefaultBorderTimeoutId);
                    }
                    try {
                        if (typeof this.$store.state.set_chime_meeting_session.audioVideo.stopAudioInput !== "undefined") {
                            await this.$store.state.set_chime_meeting_session.audioVideo.stopAudioInput()
                        } // per https://aws.github.io/amazon-chime-sdk-js/modules/migrationto_3_0.html
                        await this.$api.delete(chime.deleteMeeting(this.$store.state.set_chime_meeting_session.configuration.meetingId));
                        await this.clear_meeting(0);
                        await this.clear_chime_persist();
                        await this.endMeetingSession();
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            formatDate(dayt) {
                return dayjs(dayt).format('dddd, MMMM DD, YYYY, [at] h:mm A');
            },
            async openSettingsModal() {
                this.exitFullScreen();
                this.startSettingsVideoPreview();
                this.$refs.settingsModal.openModal();
            },
            startSettingsVideoPreview() {
                const video = this.getSettingsVideoPreviewElement();
                try {
                    navigator.mediaDevices
                        .getUserMedia({ video: { deviceId: { exact: this.selected.videoInputDeviceId } } })
                        .then((stream) => (video.srcObject = stream));
                } catch (e) {
                    console.warn(e);
                }
            },
            getSettingsVideoPreviewElement() {
                return document.getElementById('video-preview');
            },
            async pick_the_right_mike() { // simulate picking the first input not default, which seems to make it all work
                this.selected.audioInputDeviceId = this.audioInputDeviceOptions[1].value
                await this.setAudioInputDeviceById(this.selected.audioInputDeviceId);
                await this.focus_voice();
                return 1
            },
            async submitSettings() {
                // If anything has changed, then set it
                const audioInputChanged =
                    this.selected.audioInputDeviceId !== this.currentlySetDevice.audioInput.deviceId;
                const audioOutputChanged =
                    this.selected.audioOutputDeviceId !== this.currentlySetDevice.audioOutput.deviceId;
                const videoInputChanged =
                    this.selected.videoInputDeviceId !== this.currentlySetDevice.videoInput.deviceId;

                if (audioInputChanged) {
                    await this.setAudioInputDeviceById(this.selected.audioInputDeviceId);
                    await this.focus_voice();
                }
                if (audioOutputChanged && this.defaultBrowserBehavior.supportsSetSinkId()) {
                    await this.setAudioOutputDevice(this.selected.audioOutputDeviceId);
                }
                if (videoInputChanged) {
                    await this.setVideoInputDeviceById(this.selected.videoInputDeviceId);
                }

                this.closeSettingsModal();
            },
            async focus_voice() {
                if (!this.voice_focus_device) {
                    this.voice_focus_device = await this.voice_transformer.createTransformDevice(this.selected.audioInputDeviceId);
                    if (this.voice_focus_device) {
                        await this.voice_focus_device.observeMeetingAudio(this.$store.state.set_chime_meeting_session.audioVideo);
                    }
                }
            },
            closeSettingsModal() {
                this.stopSettingsVideoPreview();
                this.$refs.settingsModal.closeModal();
            },
            stopSettingsVideoPreview() {
                const video = this.getSettingsVideoPreviewElement();
                const stream = video?.srcObject;

                if (video && stream) {
                    const tracks = stream.getTracks();

                    for (let i = 0; i < tracks.length; i++) {
                        let track = tracks[i];
                        track.stop();
                    }

                    video.srcObject = null;
                }
            },
            async setAudioInputDeviceById(deviceId) {
                const device = this.findDeviceByDeviceId(this.audioInputDevices, deviceId);
                await this.setAudioInputDevice(device);
                await this.focus_voice();
            },
            async setVideoInputDeviceById(deviceId) {
                const device = this.findDeviceByDeviceId(this.videoInputDevices, deviceId);
                await this.setVideoInputDevice(device);
            },
            async toggleScreenContentShare() {
                this.isFullScreen = false;
                if (this.contentSharing) {
                    await this.stopScreenContentShare();
                } else {
                    await this.startScreenContentShare();
                }
            },
            async stopScreenContentShare() {
                if (this.contentSharing) {
                    this.contentSharing = false;
                    await this.$store.state.set_chime_meeting_session.audioVideo.stopContentShare();
                }
            },
            async startScreenContentShare() {
                if (!this.contentSharing) {
                    // We don't set screenSharing = true here because the screen sharing doesn't actually start immediately.
                    // Instead, it is done in an observer which sets it when the screen sharing starts.
                    try {
                        await this.$store.state.set_chime_meeting_session.audioVideo.startContentShareFromScreenCapture(); // Returns contentShareStream
                    } catch (e) {
                        // User probably cancelled screen share
                        this.$cl(`Screen share failed with ${e}`);
                    }
                }
            },
            async resetActiveSpeakerPolicy() {
                this.$store.state.set_chime_meeting_session && (await this.$store.state.set_chime_meeting_session.audioVideo.unsubscribeFromActiveSpeakerDetector());
                this.$store.state.set_chime_meeting_session && this.subscribeToActiveSpeakerDetector();
            },
            onResize(e) {
                e.target.style.objectFit = e.target.videoHeight < e.target.videoWidth ? 'cover' : 'contain';
            },

            //Sets a screen clicked on as the biggest screen in the meeting.
            setToBiggestOnPersonalScreen(event, refId){
                let index = this.videoElementIds.indexOf(refId);
                let firstElement = this.videoElementIds[0];
                this.$set(this.videoElementIds, index, firstElement);
                this.$set(this.videoElementIds, 0, refId);
            },
            setFullScreen(event, refId) {
                const id = refId;
                const ref = this.$refs[refId];
                if (this.isFullScreen === id) {
                    this.isFullScreen = '';
                    ref[0].classList.remove("isFullScreen");

                } else {
                    this.isFullScreen = id;
                    ref[0].classList.add("isFullScreen");

                }

            },
            showJoin(startTime, endTime) {
                const earliest = dayjs(startTime).add(-15, 'minute');
                const latest = dayjs(endTime).add(60, 'minute');

                const now = dayjs();
                return now.isBetween(earliest, latest);
            },
            get_chime_persist(e, d = null) {
                let chime_meeting_id = this.$store.state.chime_meeting_id;
                let z = d;
                if (chime_meeting_id && chime_meeting_id[e]) {
                    z = chime_meeting_id[e];
                }
                return z
            },
            set_chime_persist(e,v) {
                let chime_meeting_id = this.$store.state.chime_meeting_id
                if (chime_meeting_id) {
                    chime_meeting_id[e] = v
                } else {
                    chime_meeting_id = {}
                    chime_meeting_id[e] = v
                }
                this.$store.commit('persist_chime_meeting_id', chime_meeting_id)
                let chimes = (typeof chime_meeting_id === 'object') ? JSON.stringify(chime_meeting_id) : null;
                this.$api.put(`/users/save-chime/`, {
                    chime_meeting_id: chimes
                });
            },
            async clear_chime_persist() {
                this.$store.commit('persist_chime_meeting_id', null);
                this.$store.commit('clearCurrentMeetingSession');
                await this.$api.delete(`/users/delete-chime/`, {
                    chime_meeting_id: null
                });
            },
            async clear_meeting(all = 1) {
                if (this.readyToJoinIntervalId) {
                    window.clearInterval(this.readyToJoinIntervalId);
                }
                if (this.waitListGetterIntervalId) {
                    window.clearInterval(this.waitListGetterIntervalId);
                }
                if (this.activeSpeakerDefaultBorderTimeoutId) {
                    window.clearTimeout(this.activeSpeakerDefaultBorderTimeoutId);
                }
                if (all) {
                    if (this.userIsHost) {
                        await this.endMeeting();
                    } else {
                        await this.leaveMeeting();
                    }
                }
                this.isStartButtonDisabled=false
                await this.clear_chime_persist();
                await this.resetPageData();
            },
            async acceptAll() {
                for (const waitListMember of this.waitList) {
                    this.admitFromWaitList(waitListMember.encoded_user_id);
                }
            },
            toggleFullscreen() {
                if( !this.isFullScreen ) {
                    this.openFullScreen();
                }
                else {
                    this.exitFullScreen();
                }
            },
            openFullScreen() {
                var elem = document.getElementById("vid-child");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
                this.isFullScreen = true;
            },
            exitFullScreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
                this.isFullScreen = false;
            },
            getClientVideoElementId() {
                const providerDetails = JSON.parse(localStorage.getItem('cn-user'));
                let attendees = Object.values( this.attendees );
                attendees = attendees.filter( attendee => attendee.name != providerDetails.name );
                return attendees && attendees.length > 0 ? attendees[0].id : false;
            },
            async createApptLog() {
                const sessionId = this.get_chime_persist('sessionId');
                if( !sessionId ) {
                    const requestToken = this.get_chime_persist('requestToken');
                    const { data: { sucess, sessionId } } = await this.$api.post(apptLog.createApptLog(), { groupUuid: requestToken });
                    if( sucess ) {
                        this.apptSessionId = sessionId;

                    }
                }
            },
            async updateApptCapturedTime() {
                let isMeetStarted = false;
                const intervalId = setInterval( async() => {

                    if( this.apptSessionId ) {
                        const { data: { success } } = await this.$api.put(apptLog.updateApptLog( this.apptSessionId ));
                        if( success ) {
                            console.log( "Appt Session Updated !" );
                        }
                        isMeetStarted = true;
                    }
                    else {
                        if( isMeetStarted ) clearInterval( intervalId );
                    }
                }, 10000 );
                
            },
            async endMeetingSession() {
                const { data: { success, message } } = await this.$api.put(apptLog.completeAppointment( this.apptSessionId ));
                this.apptSessionId = null;
                if( success ) {
                    console.log( message );
                }
            }
        },
        computed: {
            currentAppointment() {
                if (
                    this.appointments.length &&
                    (this.isClientLoggedIn || dayjs(this.appointments[0].dayt_appt_start).isBefore(dayjs()))
                ) {
                    // appointments that are happening now
                    return this.appointments.find(appt => dayjs(appt.dayt_appt_start).isBefore(dayjs()) && dayjs(appt.dayt_appt_end).isAfter(dayjs()));
                }
                return null;
            },
            upcomingAppointments() {
                // Appointments less current appointment
                if (this.currentAppointment) {
                    return this.appointments.filter(
                        (appointment) => appointment.group_uuid !== this.currentAppointment.group_uuid
                    );
                } else return this.appointments;
            },
            encodedUserId() {
                if (this.isClientLoggedIn) {
                    return 'c' + this.client.id;
                } else {
                    return 'u' + this.user.id;
                }
            },
            selfAttendeeId() {
                return this.$store.state.set_chime_meeting_session?.configuration?.credentials?.attendeeId;
            },
            selfMuteButtonText() {
                return this.selfMuted ? 'Unmute' : 'Mute';
            },
            selfMuteButtonIcon() {
                return this.getMicIcon(this.selfMuted);
            },
            audioInputDeviceOptions() {
                return this.getOptionsFromDevicesList(this.audioInputDevices);
            },
            audioOutputDeviceOptions() {
                return this.getOptionsFromDevicesList(this.audioOutputDevices);
            },
            videoInputDeviceOptions() {
                return this.getOptionsFromDevicesList(this.videoInputDevices);
            },
            videoButtonText() {
                return this.localVideoStopped ? 'Start Video' : 'Stop Video';
            },
            videoButtonIcon() {
                return this.localVideoStopped ? 'videocam_off' : 'videocam';
            },
            screenShareButtonText() {
                return this.contentSharing ? 'Stop Sharing Screen' : 'Start Sharing Screen';
            },
            contentShareCount() {
                return this.receivingContentShareCount + this.contentSharing;
            },
            videoInputDeviceExists() {
                return this.videoInputDevices.length > 0;
            },
            displayAttendees() {
                // All attendees less self and content shares
                return Object.keys(this.attendees)
                    .filter((attendeeId) => !this.attendeeIdIsSelf(attendeeId) && !attendeeId.includes('#content'))
                    .reduce((res, key) => ((res[key] = this.attendees[key]), res), {});
            },
            browserSupportsDeviceChoosing() {
                return this.defaultBrowserBehavior && this.defaultBrowserBehavior.supportsSetSinkId();
            },
            browserSupportsScreenShare() {
                return !!navigator.mediaDevices.getDisplayMedia;
            },
            isClientLoggedIn() {
                return false;
            },
            ...mapState({
                user: 'user',
                client: 'client'
            })
        },
        watch: {
            attendeePresence: {
                handler: async function(newAttendeePresence) {
                    const attendeeIds = Object.keys(this.attendees);

                    const newAttendeeIds = this.getDifference(newAttendeePresence, attendeeIds);
                    for (let newAttendeeId of newAttendeeIds) {
                        await this.handleNewAttendee(newAttendeeId);
                    }

                    const removedAttendeeIds = this.getDifference(attendeeIds, newAttendeePresence);
                    for (let removedAttendeeId of removedAttendeeIds) {
                        this.handleRemovedAttendee(removedAttendeeId);
                    }
                },
                deep: true
            },
            selfMuted: function notifyOfMuteStateChange(newSelfMuted) {
                const mutedText = this.getMutedText(newSelfMuted).toLowerCase();
                const text = 'You are now ' + mutedText;
                this.$toasted.info(text);
            },
            waitList: function notifyOfNewWaitListMember(newWaitList, oldWaitList) {
                const newWaitListMembers = this.getDifference(newWaitList, oldWaitList);
                if (newWaitListMembers.length > 0) {
                    this.$toasted.info('Someone joined the wait list.');
                }
            },
            contentShareCount: function addDisableClassToContentShareButton(newCount) {
                let el = document.getElementById('toggleScreenShareButton');
                if (el) {
                    if (!this.contentSharing && newCount > 1) {
                        el.classList.add('screen-share-disabled');
                    } else {
                        el.classList.remove('screen-share-disabled');
                    }
                }
            },
            'selected.videoInputDeviceId': function() {
                this.$refs.settingsModal.isOpen ? this.startSettingsVideoPreview() : null;
            },
            meetingSession: function(newMeetingSession) {
                if (newMeetingSession) {
                    this.$emit('waitBeforeLeaving', true);
                } else {
                    this.$emit('waitBeforeLeaving', false);
                }
            }
        }
    };
    // https://app.chime.aws/check#
</script>

<style scoped>
    video::-webkit-media-controls-timeline,
    video::-webkit-media-controls-current-time-display,
    video::-webkit-media-controls-play-button,
    video::-webkit-media-controls-mute-button,
    video::-internal-media-controls-overflow-button,
    video::-internal-media-controls-playback-speed-list,
    video::-internal-media-controls-overflow-menu-list {
        display: none !important;
    }

    .main-video {
        border-radius: 20px;
    }

    .agenda {
        font-size: 16px;
        font-weight: 400;
        font-family: 'roboto';
        color: #9BA4A9;
    }

    .agenda-title {
        font-size: 25px;
        font-weight: 500;
        font-family: 'roboto';
    }

    .host-section {
        border-bottom: 1px solid #E5E8E9;
    }

    .username {
        font-size: 22px;
        font-weight: 400;
        font-family: 'roboto';
    }

    .host-tag {
        background: #DFEBFF;
        text-align: center;
        border-radius: 29px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'roboto';
        cursor: pointer;
    }

    .accept-all {
        color: #209F84;
        border: 1px solid #209F84;
    }

    .meeting-avatar {
        width: 40px;
    }

    .member-icon {
        font-size: 30px; 
        border-radius: 50%;
        background: rgb(31, 59, 179);
        color: #FFFFFF;
        padding: 2px;
    }

    .member-icon-host {
        font-size: 30px;
        border-radius: 50%;
        background: rgb(31, 59, 179);
        color: #FFFFFF;
        padding: 6px;
        margin: 0 10px 0 0;
    }

    .meeting-name {
        font-size: 14px;
        font-weight: 400;
        font-family: 'roboto';
        margin-left: 10px;
    }

    .icon-accept {
        background: #4CBC9A;
        color: #FFFFFF;
        border-radius: 50px;
        width: 35px;
        cursor: pointer;
    }

    .icon-reject {
        background: #DB4040;
        color: #FFFFFF;
        border-radius: 50px;
        width: 35px;
        cursor: pointer;
    }

    .icon-mute-unmute {
        background: #FFFFFF;
        color: #DB4040;
        border-radius: 50px;
        width: 35px;
        height: 28px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
        cursor: pointer;
    }

    .mute-btns {
        border: 1px solid #5155C3;
        color: #5155C3;
        font-size: 14px;
        font-family: 'roboto';
        border-radius: 5px;
        cursor: pointer;
    }

    .mute-btns:hover {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        background: #5155C3;
        font-size: 14px;
        font-family: 'roboto';
        border-radius: 5px;
        cursor: pointer;
    }

    .essential-icon {
        background: #FFFFFF;
        color: #5155C3;
        width: 45px;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
        cursor: pointer;
    }

    .end-meeting-section {
        background: #D31800;
        color: #FDFBFB;
        border-radius: 15px;
        cursor: pointer;
    }

    .other-participants-video-section {
        width: 25%;
        right: 0;
        top: 0;
        height: 95%;
        overflow: auto;
        margin: 1% 3%;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .other-participants-video-section::-webkit-scrollbar {
        display: none;
    }

    .other-participants-name {
        border-top-right-radius: 15px; 
        border-bottom-left-radius: 10px; 
        opacity: .85;
        margin-left: 3px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px var(--bs-gray-300); 
        border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--bs-gray-300); 
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--bs-gray-400); 
    }

    video::-webkit-media-controls-play-button, 
    video::-webkit-media-controls-timeline,
    video::-webkit-media-controls,
    video::-webkit-media-controls-pausebutton {
        display: none;
    }
    
    #vid-parent:fullscreen {
        background: #FFFFFF;
        padding: 20px 30px;
    }

</style>